import Grid from "@mui/material/Grid";
import NavbarItem from "../NavbarItem/NavbarItem";
import LineBreak from "../LineBreak/LineBreak";
const styles = {
  Navbar: {
    position: "absolute",
    height: 64,
    width: "100%",
    // backgroundColor: "#333333",
    backgroundColor: "transparent",
    zIndex: 100,
  },
  Centered: {
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
};
const Navbar = ({ screenWidth }) => {
  return (
    <div style={styles["Navbar"]} id="navbar">
      <Grid container style={styles["Centered"]}>
        <NavbarItem label="Home" screenWidth={screenWidth} to="home" />
        <NavbarItem label="About" screenWidth={screenWidth} to="about" />
        <NavbarItem label="Projects" screenWidth={screenWidth} to="projects" />
        <NavbarItem label="Resume" screenWidth={screenWidth} to="resume" />
        <NavbarItem label="Contact" screenWidth={screenWidth} to="contact" />
      </Grid>
      <LineBreak />
    </div>
  );
};

export default Navbar;
