import { projectsCalculateHeight, projectsTop } from "../helpers/helpers";
import WorkExperience from "../WorkExperience/WorkExperience";
import TitleField from "../Components/TitleField/TitleField";
const Resume = ({ screenWidth, screenHeight }) => {
  const styles = {
    Resume: {
      position: "absolute",
      height: "100%",
      width: "100%",
      backgroundColor: "#0E0E0E",
      top: `${
        projectsCalculateHeight(screenWidth, screenHeight) +
        projectsTop(screenWidth, screenHeight)
      }px`,
    },
    Title: {
      textAlign: "center",
      fontSize: 25,
      fontWeight: "bold",
      transform: "translate(0%, 100%)",
      color: "white",
    },
    FieldTitle: {
      color: "#F06000",
      transform: "translate(0%, 100%)",
      fontSize: 20,
      fontWeight: "bold",
      textAlign: "center",
    },
  };
  return (
    <div style={styles["Resume"]} id="resume">
      <TitleField title="Resume" />
      <div style={styles["Title"]}>Work Experience</div>
      <WorkExperience />
    </div>
  );
};

export default Resume;
