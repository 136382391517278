const styles = {
  TitleField: {
    color: "#F06000",
    transform: "translate(0%, 100%)",
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
  },
};

const TitleField = ({ title }) => {
  return <div style={styles["TitleField"]}>{title}</div>;
};

export default TitleField;
