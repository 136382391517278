const HomeText = ({ screenWidth }) => {
  const styles = {
    TextName: {
      // text
      color: "white",
      fontWeight: "bold",
      fontSize: screenWidth >= 670 ? 100 : 60,
      textShadow: "-1px 1px 10px rgba(0, 0, 0, 0.75)",
    },
    TextField: {
      // text
      color: "white",
      fontWeight: "bold",
      fontSize: screenWidth >= 670 ? 30 : 25,
      textShadow: "-1px 1px 10px rgba(0, 0, 0, 0.75)",
    },
  };
  return (
    <div>
      <div style={styles["TextName"]}>Mustafa Natheir</div>
      <div style={styles["TextField"]}>
        Software Engineer & University Student
      </div>
    </div>
  );
};

export default HomeText;
