import Experience from "./Experience";
const styles = {
  WorkExperience: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    marginTop: 25,
  },
};
const WorkExperience = () => {
  return (
    <div style={styles["WorkExperience"]}>
      <Experience
        jobTitle="Web Developer"
        dateRange="July 2022 - Present"
        workplace="Noor Dental"
        description="Worked on creating and maintaining the Noor Dental
                    website, integrated with many third party APIs 
                    such as Google Maps API to increase patient count.
                    Resulted in a 45% year/year increase in digitally-referred patients and over $500,000 in additional revenue"
      />
      <Experience
        jobTitle="Software Engineering Intern"
        dateRange="June 2021 - June 2022"
        workplace="APOLLO"
        description="Worked on InsurTech website used to make buying personal 
                    and business insurance easier. Focused on full stack development
                    where I increased efficiency of deployment pipelines by 80% and
                    optimized search algorithm to help redirect over $400,000 towards appropriate insurance products."
      />
    </div>
  );
};

export default WorkExperience;
