import Introduction from "./Introduction";
import AboutMe from "./AboutMe";
import Skills from "./Skills";
import Grid from "@mui/material/Grid";
import Button from "../Button/Button";
const About = ({ screenWidth }) => {
  const styles = {
    About: {
      position: "absolute",
      width: "100%",
      height: screenWidth >= 670 ? 1200 : 1700,
      top: "100%",
      backgroundColor: "#0E0E0E",
    },
    Container: {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform:
        screenWidth > 636 ? "translate(-50%, -30%)" : "translate(-50%, 0%)",
      justifyContent: "center",
    },
    Buttons: {
      justifyContent: "center",
      textTransform: "none",
    },
  };
  return (
    <div style={styles["About"]} id="about">
      <Introduction />
      <Grid container style={styles["Container"]}>
        <Grid item>
          <AboutMe />
        </Grid>
        <Grid item>
          <Skills />
        </Grid>
        <Grid container style={styles["Buttons"]}>
          <Grid item>
            <Button
              text="Get in touch"
              href="mailto:mustafa.natheir@mail.utoronto.ca"
            />
          </Grid>
          <Grid item>
            <Button
              text="Connect with me"
              href="https://www.linkedin.com/in/natheir/"
              target="_blank"
              rel="noreferrer"
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default About;
