import ProjectCard from "./ProjectCard";
import Grid from "@mui/material/Grid";
const styles = {
  ProjectsRow: {
    justifyContent: "center",
  },
  Projects: {
    marginTop: 50,
  },
};
const MyProjects = () => {
  return (
    <div style={styles["Projects"]}>
      <Grid container style={styles["ProjectsRow"]}>
        <Grid item>
          <ProjectCard
            title="Search Optimization"
            imgUrl="https://assets-mustafa-natheir.s3.amazonaws.com/apollocoverSearchOptimization.png"
            altText="Apollo Capstone Search Optimization"
            text="4 month capstone project implementing search optimization that led to over $400,000 dollars redirected to correct products"
          />
        </Grid>
        <Grid item>
          <ProjectCard
            title="Amatheir"
            imgUrl="https://assets-mustafa-natheir.s3.amazonaws.com/amatheir.png"
            altText="Amatheir Ecommerce Website"
            text="Amatheir is an E-Commerce website created with the purpose of implementing the knowledge I've gained during my Internship at APOLLO."
          />
        </Grid>
        <Grid item>
          <ProjectCard
            title="Sorting Algorithm Visualizer"
            imgUrl="https://assets-mustafa-natheir.s3.amazonaws.com/algorithmVisualizer.png"
            altText="Sorting Algorithm Visualizer"
            text="4 different sorting algorithms implemented and visualized for the purpose of deepening my understanding of algorithms ."
          />
        </Grid>
        <Grid item>
          <ProjectCard
            title="Maze Algorithm Visualizer"
            imgUrl="https://assets-mustafa-natheir.s3.amazonaws.com/mazeAlgorithmVisualizer.png"
            altText="Maze Algorithm Visualizer"
            text='The "Daily Mazel" project is a fun and engaging daily maze challenge game where users navigate through intricate mazes that change every day.'
            goto="https://main.d1lwrqj9nzgcw2.amplifyapp.com/mazes"
          />
        </Grid>
      </Grid>
      <Grid container style={styles["ProjectsRow"]}>
        <Grid item>
          <ProjectCard
            title="Stock Market Trading Bot"
            imgUrl="https://assets-mustafa-natheir.s3.amazonaws.com/stockbot.png"
            altText="Stock Marking Auto Trader"
            text="Machine Learning model to accurately predict when to buy and sell stock. Ran the algorithm against both real time and years of sourced data and saw profit."
          />
        </Grid>
        <Grid item>
          <ProjectCard
            title="Discord Bot"
            imgUrl="https://assets-mustafa-natheir.s3.amazonaws.com/discordbot.png"
            altText="Discord Bot"
            text="Created MMORPG discord bot to play along side friends, took their recommendations for improvements and implemented them."
          />
        </Grid>
        <Grid item>
          <ProjectCard
            title="Black Jack Probability Calculator"
            imgUrl="https://assets-mustafa-natheir.s3.amazonaws.com/blackjack.png"
            altText="Black Jack Probability Calculator and Game"
            text="The game Black Jack with a probability calculator showing when its optimal to hit and/or fold."
          />
        </Grid>
        <Grid item>
          <ProjectCard
            title="Noor Dental"
            imgUrl="https://assets-mustafa-natheir.s3.amazonaws.com/noordental.png"
            altText="Noor Dental Website"
            text="Commissioned website created for Noor Dental to encourage more patients to visit. Saw 45% increase in patients after implementation."
            goto="https://noordental.com/"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default MyProjects;
