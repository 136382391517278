import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

const styles = {
  ProjectCard: {
    margin: "25px 25px 25px 25px",
    width: 300,
    height: 300,
    borderRadius: 25,
  },
  Title: {
    fontSize: 20,
  },
};
const ProjectCard = ({ title, imgUrl, text, altText, goto = "" }) => {
  let url = "";
  if (goto !== "") {
    url = goto;
  } else {
    url = imgUrl;
  }
  return (
    <Card sx={{ maxWidth: 345 }} style={styles["ProjectCard"]}>
      <a href={url} target="_blank" rel="noreferrer">
        <CardMedia
          component="img"
          sx={{ width: 300, height: 150 }}
          image={imgUrl}
          alt={altText}
        />
      </a>
      <CardContent>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          style={styles["Title"]}
        >
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {text}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ProjectCard;
