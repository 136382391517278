import Box from "@mui/material/Box";
import HomeText from "./HomeText";
import HomeIcons from "./HomeIcons";
const styles = {
  Home: {
    position: "absolute",
    top: 0,
    height: "100%",
    width: "100%",
    pointerEvents: "none",
    userSelect: "none",
  },
  HomeText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
};
const Home = ({ screenWidth }) => {
  return (
    <div id="home">
      <Box
        component="img"
        src="https://assets-mustafa-natheir.s3.amazonaws.com/Creation_of_Nature.jpg"
        style={styles["Home"]}
      ></Box>
      <div style={styles["HomeText"]}>
        <HomeText screenWidth={screenWidth} />
        <HomeIcons screenWidth={screenWidth} />
      </div>
    </div>
  );
};

export default Home;
