import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
const styles = {
  Profile: {
    transform: "translate(0%, 50%)",
  },
  Me: {
    borderRadius: "100%",
    width: 200,
    height: 200,
  },
  AboutMeText: {
    marginLeft: 20,
    color: "white",
    fontSize: 18,
    textShadow: "-1px 1px 10px rgba(0, 0, 0, 0.75)",
    transform: "translate(0%, 10%)",
    textAlign: "center",
    width: 300,
  },
};
const Profile = () => {
  return (
    <div style={styles["Profile"]}>
      <Grid container justifyContent="center">
        <Grid item>
          <Box
            component="img"
            src="https://assets-mustafa-natheir.s3.amazonaws.com/MustafaNatheir.JPG"
            style={styles["Me"]}
          />
        </Grid>
        <Grid item>
          <div style={styles["AboutMeText"]}>
            I'm an aspiring software engineer studying computer science,
            statistics, and math at the University of Toronto. I am currently
            interested in Web and App development, script automation, and game
            dev.
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Profile;
