import ProgressBar from "../ProgressBar/ProgessBar";
const styles = {
  Skills: {
    marginBottom: 25,
    marginLeft: 25,
    marginRight: 25,
    color: "#F06000",
    width: 300,
  },
  Title: {
    textAlign: "center",
    fontSize: 20,
    marginBottom: 15,
  },
  Body: {
    color: "white",
  },
};

const Skills = () => {
  return (
    <div style={styles["Skills"]}>
      <div style={styles["Title"]}>Skills</div>
      <div style={styles["Body"]}>
        Software engineer with over a year of intern experience, helping with
        the development of an e-commerce website that revolutionizes the
        purchasing of insurance, currently working on improving my fullstack
        experience by working on full stack applications, implementing the
        knowledge I learned during my internship.
        <ProgressBar title="Javascript/Nodejs" value={0.9} />
        <ProgressBar title="AWS" value={0.85} />
        <ProgressBar title="Python" value={0.8} />
        <ProgressBar title="Javascript/React" value={0.7} />
        <ProgressBar title="Java" value={0.65} />
        <ProgressBar title="SQL/Databases" value={0.65} />
      </div>
    </div>
  );
};

export default Skills;
