import { projectsCalculateHeight, projectsTop } from "../../helpers/helpers";
import HomeIcons from "../Home/HomeIcons";
import TitleField from "../TitleField/TitleField";
const Footer = ({ screenWidth, screenHeight }) => {
  const styles = {
    Footer: {
      position: "absolute",
      height: "50%",
      width: "100%",
      backgroundColor: "#F9FEFF",
      top: `${
        projectsCalculateHeight(screenWidth, screenHeight) +
        projectsTop(screenWidth, screenHeight) +
        screenHeight
      }px`,
    },
    Title: {
      color: "black",
      display: "flex",
      justifyContent: "center",
      transform: "translate(0%, 100%)",
      fontSize: 25,
      fontWeight: "bold",
    },

    Connect: {
      color: "black",
      fontSize: 20,
      display: "flex",
      justifyContent: "center",
      transform: "translate(0%, 300%)",
    },
    Icons: {
      Space: {
        transform: "translate(0%, 300%)",
        marginLeft: 15,
        marginRight: 15,
        color: "black",
        fontSize: 55,
      },
      Centered: {
        justifyContent: "center",
      },
    },
  };

  return (
    <div id="contact" style={styles["Footer"]}>
      <TitleField title="Contact" />
      <div style={styles["Title"]}>Contact Me!</div>
      <div style={styles["Connect"]}>I'd love for us to connect</div>
      <HomeIcons style={styles["Icons"]} />
    </div>
  );
};

export default Footer;
