const ProgressBar = ({ title, value = 1 }) => {
  const width = 300 * value;
  const right = 300 - width;
  const styles = {
    Title: {
      marginTop: 10,
      marginBottom: 5,
      fontSize: 20,
      textAlign: "left",
    },
    Line: {
      width: `${width}px`,
      height: 10,
      backgroundColor: "#A0A0A0",
      borderRight: `#303030 ${right}px solid`,
    },
  };
  return (
    <div>
      <div style={styles["Title"]}>{title}</div>
      <div style={styles["Line"]} />
    </div>
  );
};

export default ProgressBar;
