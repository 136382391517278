const LineBreak = ({ color = "white" }) => {
  const styles = {
    Break: {
      height: "1px",
      width: "100%",
      backgroundColor: color,
    },
  };
  return <div style={styles["Break"]}></div>;
};

export default LineBreak;
