import MUIButton from "@mui/material/Button";
const styles = {
  Button: {
    marginLeft: 10,
    marginRight: 10,
    backgroundColor: "#11ABB0",
  },
};
const Button = (props) => {
  const { text, href } = props;
  return (
    <MUIButton
      {...props}
      href={href}
      variant="contained"
      style={styles["Button"]}
    >
      {text}
    </MUIButton>
  );
};

export default Button;
