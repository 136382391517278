export const projectsCalculateHeight = (screenWidth, screenHeight) => {
  let height = screenHeight;
  if (screenWidth <= 1417) {
    height = 1200;
  }
  if (screenWidth <= 1066) {
    height = 1500;
  }
  if (screenWidth <= 716) {
    height = 2700;
  }
  return height;
};

export const projectsTop = (screenWidth, screenHeight) => {
  return screenHeight + (screenWidth > 670 ? 1200 : 1700);
};
