import Grid from "@mui/material/Grid";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import EmailIcon from "@mui/icons-material/Email";
const styles = {
  Space: {
    marginLeft: 15,
    marginRight: 15,
    color: "white",
  },
  Centered: {
    justifyContent: "center",
  },
};
const HomeIcons = ({ screenWidth, style = styles }) => {
  const fontSize = screenWidth >= 670 ? 50 : 40;
  return (
    <Grid container style={styles["Centered"]}>
      <Grid item>
        <a
          href="https://www.linkedin.com/in/natheir/"
          target="_blank"
          rel="noreferrer"
        >
          <LinkedInIcon sx={{ fontSize }} style={style["Space"]} />
        </a>
      </Grid>
      <Grid item>
        <a href="https://github.com/NatheirX" target="_blank" rel="noreferrer">
          <GitHubIcon sx={{ fontSize }} style={style["Space"]} />
        </a>
      </Grid>
      <Grid item>
        <a href="mailto:mustafa.natheir@mail.utoronto.ca">
          <EmailIcon sx={{ fontSize }} style={style["Space"]} />
        </a>
      </Grid>
    </Grid>
  );
};
export default HomeIcons;
