import Navbar from "./Components/Navbar/Navbar";
import Home from "./Components/Home/Home";
import About from "./Components/About/About";
import Projects from "./Components/Projects/Projects";
import theme from "./theme";
import { ThemeProvider } from "@mui/material";
import { useState } from "react";
import Resume from "./Resume/Resume";
import Footer from "./Components/Footer/Footer";

function App() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  window.addEventListener("resize", () => {
    setScreenWidth(window.innerWidth);
  });
  window.addEventListener("resize", () => {
    setScreenHeight(window.innerHeight);
  });
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Navbar screenWidth={screenWidth} />
        <Home screenWidth={screenWidth} />
        <About screenWidth={screenWidth} />
        <Projects screenWidth={screenWidth} screenHeight={screenHeight} />
        <Resume screenWidth={screenWidth} screenHeight={screenHeight} />
        <Footer screenWidth={screenWidth} screenHeight={screenHeight} />
      </div>
    </ThemeProvider>
  );
}

export default App;
