import Grid from "@mui/material/Grid";
const styles = {
  Experience: {
    color: "white",
    marginBottom: 25,
    marginTop: 25,
  },
  JobTitle: {
    fontWeight: "bold",
    fontSize: 20,
  },
  WorkPlace: {
    fontSize: 20,
    fontWeight: "bold",
  },
  Description: {},
  GridItem: {
    width: 300,
  },
  dateRange: {
    marginBottom: 10,
  },
};
const Experience = ({ jobTitle, workplace, dateRange, description }) => {
  return (
    <div style={styles["Experience"]}>
      <Grid container>
        <Grid item style={styles["GridItem"]}>
          <div style={styles["JobTitle"]}>{jobTitle}</div>
          <div style={styles["dateRange"]}>{dateRange}</div>
        </Grid>
        <Grid item style={styles["GridItem"]}>
          <div style={styles["WorkPlace"]}>{workplace}</div>
          <div style={styles["Description"]}>{description}</div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Experience;
