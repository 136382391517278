import MyProjects from "./MyProjects";
import { projectsCalculateHeight, projectsTop } from "../../helpers/helpers";
import TitleField from "../TitleField/TitleField";
const Projects = ({ screenWidth, screenHeight }) => {
  const styles = {
    Projects: {
      height: `${projectsCalculateHeight(screenWidth, screenHeight)}px`,
      width: "100%",
      position: "absolute",
      backgroundColor: "#F9FEFF",
      top: `${projectsTop(screenWidth, screenHeight)}px`,
    },
    Title: {
      color: "#0E0E0E",
      fontWeight: "bold",
      transform: "translate(0%, 100%)",
      fontSize: 25,
      textAlign: "center",
    },
    FieldTitle: {
      color: "#F06000",
      transform: "translate(0%, 100%)",
      fontSize: 20,
      fontWeight: "bold",
      textAlign: "center",
    },
  };
  return (
    <div style={styles["Projects"]} id="projects">
      <TitleField title="Projects" />
      <div style={styles["Title"]}>
        Take a look at the projects I've worked on
      </div>
      <div style={styles["AllProjects"]}>
        <MyProjects />
      </div>
    </div>
  );
};

export default Projects;
