import TitleField from "../TitleField/TitleField";
import Profile from "./Profile";
const styles = {
  Intro: {
    fontSize: 25,
    display: "flex",
    transform: "translate(0%, 100%)",
    textShadow: "-1px 1px 10px rgba(0, 0, 0, 0.75)",
    justifyContent: "center",
    fontWeight: "bold",
    color: "white",
  },
};
const Introduction = () => {
  return (
    <div>
      <TitleField title="About" />
      <div style={styles["Intro"]}>Let me introduce myself</div>
      <Profile />
    </div>
  );
};

export default Introduction;
