import Grid from "@mui/material/Grid";
import { Link } from "react-scroll";
const NavbarItem = ({ label, screenWidth, to }) => {
  const styles = {
    centered: {
      // positioning
      marginLeft: screenWidth >= 670 ? 25 : 15,
      marginRight: screenWidth >= 670 ? 25 : 15,
      // text
      fontWeight: "bold",
      color: "white",
      textShadow: "-1px 1px 10px rgba(0, 0, 0, 0.75)",
      fontSize: 20,
    },
    Link: {
      color: "white",
      textDecoration: "none",
    },
  };
  return (
    <Grid item style={styles["centered"]}>
      <a href="/" style={styles["Link"]}>
        <Link to={to} smooth={true} duration={1000}>
          {label}
        </Link>
      </a>
    </Grid>
  );
};

export default NavbarItem;
