const styles = {
  AboutMe: {
    marginLeft: 25,
    marginRight: 25,
    marginBottom: 25,
    color: "#F06000",
    width: 300,
  },
  Title: {
    textAlign: "center",
    fontSize: 20,
    marginBottom: 15,
  },
  Email: {
    color: "#11ABB0",
    marginLeft: 5,
  },
  Body: {
    color: "white",
  },
};
const AboutMe = () => {
  return (
    <div style={styles["AboutMe"]}>
      <div style={styles["Title"]}>Me</div>
      <div style={styles["Body"]}>
        I am currently a fourth year student at the University of Toronto
        studying computer science and statistics. If you have any opportunities,
        please reach out at
        <a
          href="mailto:Mustafa.natheir@mail.utoronto.ca"
          style={styles["Email"]}
        >
          Mustafa.natheir@mail.utoronto.ca
        </a>
      </div>
    </div>
  );
};

export default AboutMe;
